<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-if="!getLoading && requestData">
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="my-0" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h6 class="text-h6 grey--text text--darken-1 mb-0">ID: {{ requestData.id }}</h6>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-4" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4 mb-0">{{ requestData.title }}</h1>
        </v-col>
        <v-col cols="auto" class="text-right">
          <h6 class="text-h6 mb-0">
            {{ 'status'|localize }}:
            <v-icon
              :color="STATUS_COLORS[requestData.status]"
              size="20"
              class="mx-2 mb-1"
            >{{ STATUS_ICONS[requestData.status] }}
            </v-icon>
            <span class="font-weight-regular">{{ requestData.status|localize }}</span>
          </h6>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-8" align="center" no-gutters>
        <v-col cols="auto" class="d-flex align-center">
          <div class="text-subtitle-1 mb-0">{{ 'reference'|localize }}:</div>
          <div class="mb-0 ml-4"> {{ requestData.request_customer_name }}</div>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-row class="my-0 mb-1">
          <v-col class="py-0">
            <v-tabs v-model="tab" @change="$router.replace({query: $router.currentRoute.query, hash: tab})" background-color="transparent" class="grey--text text--lighten-1">
              <v-tab :ripple="false" href="#quotes" class="text-none">
                {{ 'allQuotations'|localize }}
              </v-tab>
              <v-tab :ripple="false" href="#requirements" class="text-none">
                {{ 'requirements'|localize }}
              </v-tab>
            </v-tabs>
            <v-divider/>
          </v-col>
        </v-row>
        <template v-if="tab === 'requirements'">
          <v-container class="px-4">
            <RequestForm
              ref="requestForm"
              @save="saveRequest($event)"
              @send="sendRequest($event)"
              :request_data="requestData"
              formMode="edit"
            />
            <v-row>
              <v-col cols="6">
                <v-btn
                  v-if="isDraft || isNew || isNeedResponse"
                  rounded
                  outlined
                  color="red"
                  :loading="cancelLoading"
                  :disabled="saveLoading || sendLoading || cancelLoading"
                  @click="$refs.cancelDialog.open()"
                >
                  {{ 'cancelRequest'|localize }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn
                  v-if="isDraft"
                  rounded
                  outlined
                  color="primary"
                  :loading="saveLoading"
                  :disabled="saveLoading || sendLoading || cancelLoading"
                  @click="$refs.requestForm.save()"
                >
                  {{ 'save'|localize }}
                </v-btn>
                <v-btn
                  v-if="isDraft"
                  depressed
                  rounded
                  color="primary"
                  class="ml-2"
                  :loading="sendLoading"
                  :disabled="saveLoading || sendLoading || cancelLoading"
                  @click="$refs.sendDialog.open()"
                >
                  {{ 'sendRequest'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <ClientQuotes
            :request_type="requestData.request_type"
            @updateRequestData="updateRequest"
          />
        </template>
      </div>
    </template>
    <SimpleDialog
      ref="cancelDialog"
      :title="'cancelRequest'|localize"
      :text="'cancelRequestText'|localize"
      :accept-text="'cancelRequest'|localize"
      :decline-text="'back'|localize"
      @accept="cancelRequest"
    />
    <SimpleDialog
      ref="sendDialog"
      :title="'sendRequest'|localize"
      :text="'sendRequestText'|localize"
      :accept-text="'sendRequest'|localize"
      :decline-text="'back'|localize"
      @accept="$refs.requestForm.send()"
    />
  </v-container>
</template>

<script>
  import RequestForm from '@/components/RequestForm';
  import ClientQuotes from '@/components/ClientQuotes.vue';
  import api from '@/api/api';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import methods from '@/helpers/methods';
  import localize from '@/filters/localize';
  import {STATUSES, STATUS_ICONS, STATUS_COLORS} from '@/enums/statuses';

  export default {
    name: 'RequestDetails',
    components: {SimpleDialog, RequestForm, ClientQuotes},

    data() {
      return {
        STATUSES,
        STATUS_ICONS,
        STATUS_COLORS,
        requestId: this.$router.currentRoute.query.request_id,
        getLoading: false,
        cancelLoading: false,
        saveLoading: false,
        sendLoading: false,
        requestData: null,
        tab: this.initialHash(),
      };
    },

    async mounted() {
      try {
        this.getLoading = true;
        let remoteData = await api.getSingleCustomerRequest(this.requestId);
        this.requestData = remoteData.data['request_form'];
        this.getLoading = false;
      } catch (e) {
        this.getLoading = false;
      }
    },

    computed: {
      isDraft() {
        return this.requestData && this.requestData.status === STATUSES.DRAFT;
      },
      isNew() {
        return this.requestData && this.requestData.status === STATUSES.NEW;
      },
      isNeedResponse() {
        return this.requestData && this.requestData.status === STATUSES.NEED_RESPONSE;
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('myQuotes'),
            disabled: false,
            to: {name: 'customer-requests'}
          },
          {
            text: localize('allQuotations'),
            disabled: true,
          },
        ]
      },
    },

    methods: {
      async saveRequest(queryData) {
        queryData.id = this.requestId;
        try {
          this.saveLoading = true;
          await api.updateCustomerRequest(queryData);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'customer-requests'});
        } catch (e) {
          this.saveLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      async cancelRequest() {
        try {
          this.cancelLoading = true;
          await api.cancelCustomerRequest(this.requestId);
          this.$router.push({name: 'customer-requests'});
        } catch (e) {
          this.cancelLoading = false;
        }
      },
      async sendRequest(queryData) {
        queryData.id = this.requestId;
        try {
          this.sendLoading = true;
          await api.sendCustomerRequest(queryData);
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'customer-requests'});
        } catch (e) {
          this.sendLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      updateRequest(newRequestData) {
        this.requestData = newRequestData;
      },
      initialHash() {
        const queryHash = this.$router.currentRoute.hash.slice(1);
        return queryHash ? queryHash : 'quotes';
      },
    },
  };
</script>
